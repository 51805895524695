import { useEffect } from "react";
import { useRouter } from "next/router";
import { useProfile } from "hooks/profile";

export function useRequireConfirmation(skipCheck = false): {
  needsConfirmation: boolean;
} {
  const router = useRouter();
  const { user: profile, isLoading } = useProfile();

  useEffect(() => {
    if (skipCheck || isLoading || !profile) return;

    const currentPath = router.pathname;
    if (currentPath === "/cadastro/confirmacao") return;

    if (!profile.confirmedAt) {
      const query = router.query;
      const redirectTo = query.redirect_to
        ? `?redirect_to=${query.redirect_to}`
        : "";

      router.push(`/cadastro/confirmacao${redirectTo}`);
    }
  }, [profile, isLoading, router, skipCheck]);

  return { needsConfirmation: !!(profile && !profile.confirmedAt) };
}
